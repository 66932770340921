import React from "react";
import InternalLink from "../InternalLink";
import { Container, Section } from "../../sdk/Layout";
import { Items, Item } from "./style";
import Icons from "../Icons2/index";

function ActionsCTA({ config }) {
    if (config != null && config.length <= 0) return null;

    return (
        <Section>
            <Container>
                <Items>
                    {config.map((index, i) => (
                        <Item key={`action-cta-${i}`}>
                            <InternalLink {...index.link}>
                            {/* <Icons className="icon" icon="image-file-download"></Icons> */}
                                <h2>{index.header}</h2>
                                <p>{index.paragraph}</p>
                                <span className="indicator"><Icons icon="chevron-right"></Icons></span>
                            </InternalLink>
                        </Item>
                    ))}
                </Items>
            </Container>
        </Section>
    );
}

export default ActionsCTA;
