import styled from "styled-components";


export const Items = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0px;
  border: 0.5px solid ${(props) => props.theme.borderColor};
  border-radius: ${(props) => props.theme.radio};
  overflow: hidden;
`;

export const Item = styled.div`
  width: 33.33333%;
  align-items: stretch;
  border: 0.5px solid ${(props) => props.theme.borderColor};
  position: relative;
  a {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px;
    padding-right: 50px;
    color: ${(props) => props.theme.black};
    margin: 0;
    background: transparent;
    transition: all 200ms ease-in-out;
    .icon {
      width: 48px !important;
      height: 48px !important;
      flex: none;
    }
    h2 {
      font-weight: 800;
      font-size: 26px;
    }
    .indicator {
        position: absolute;
        right: 16px;
        bottom: 16px;
        transition: all 200ms ease-in-out;
        border-radius: 100%;
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--primary700);
        svg {
          color: var(--primary50);
          width: 16px;
          height: 16px;
        }
    }
  }
  &:hover {
    a {
      background-color: var(--primary700);
      color: white;
        .indicator { 
            background-color: var(--primary50);
            svg {
              color: var(--primary700);
            }
        }
    }
  }
  @media (max-width: 768px) {
    width: 100%;
    a {
      gap: 8px;
      padding: 16px;
      padding-right: 30px;
      .icon {
        width: 32px !important;
        height: 32px !important;
      }
      h2 {
          font-size: 16px;
      }
      p {
        font-size: 14px;
      }
        .indicator {
          bottom: 8px;
          right: 8px;
        }
    }
  }
`;